<template>
  <b-card>
    <b-form-group
        label="เปอร์เซ็นจ่ายแคชแบค *"
        label-for="cashbackCommissionRate"
    >
      <ValidationProvider
          v-slot="{ errors }"
          name="cashbackCommissionRate"
          rules="required"
      >
        <b-input-group append="%">
          <b-input
              id="cashbackCommissionRate"
              v-model="form.cashbackCommissionRate"
              :state="errors[0] ? false : null"
          ></b-input>
        </b-input-group>
      </ValidationProvider>
    </b-form-group>
    <b-form-group
        label="แคชแบคที่แจกสูงสุดต่อวัน (ต่อยูส) *"
        label-for="cashbackQuotaPerDay"
    >
      <ValidationProvider
          v-slot="{ errors }"
          name="cashbackQuotaPerDay"
          rules="required"
      >
        <b-input
            id="cashbackQuotaPerDay"
            v-model="form.cashbackQuotaPerDay"
            :state="errors[0] ? false : null"
        ></b-input>
      </ValidationProvider>
    </b-form-group>
    <b-form-group
        label="ถอนขั้นต่ำ (บาท) *"
        label-for="minWithdrawCashback"
    >
      <ValidationProvider
          v-slot="{ errors }"
          name="minWithdrawCashback"
          rules="required"
      >
        <b-input
            id="minWithdrawCashback"
            v-model="form.minWithdrawCashback"
            :state="errors[0] ? false : null"
        ></b-input>
      </ValidationProvider>
    </b-form-group>
    <b-form-group
        label="ถอนสูงสุดต่อวัน (จำนวนครั้ง) *"
        label-for="maxWithdrawCashbackTimePerDay"
    >
      <ValidationProvider
          v-slot="{ errors }"
          name="maxWithdrawCashbackTimePerDay"
          rules="required"
      >
        <b-input
            id="maxWithdrawCashbackTimePerDay"
            v-model="form.maxWithdrawCashbackTimePerDay"
            :state="errors[0] ? false : null"
        ></b-input>
      </ValidationProvider>
    </b-form-group>

    <b-form-group
        label="การจ่ายเงินคืน แบบกำหนดเวลา"
        label-for="maxWithdrawCashbackTimePerDay"
    >
      <b-form-checkbox
          id="isEnableCashbackTime"
          v-model="form.isEnableCashbackTime"
          switch
      />
    </b-form-group>
    <b-form-group>
      <template v-if="form.isEnableCashbackTime">
        <b-row
            v-for="day in daysInWeek"
            :key="day.en"
        >
          <b-col class="d-flex">
            <b-form-checkbox
                v-model="form.cashbackTime[day.en].isEnable"
                switch
            />
            <b class="mx-2">{{ day.th }}</b>
          </b-col>
          <b-col>
            <ValidationProvider
                v-slot="{ errors }"
                :name="`cashbackTime.${day.en}.startTime`"
                :rules="{required: true, regex:/^([01][0-9]|2[0-4]):([0-5][0-9])$/}"
            >
              <b-input
                  v-model="form.cashbackTime[day.en].startTime"
                  :disabled="!form.cashbackTime[day.en].isEnable"
                  :state="errors[0] ? false : null"
              />
              <b-form-text>ตัวอย่าง: 00:00</b-form-text>
            </ValidationProvider>
          </b-col>
          <b-col>
            <ValidationProvider
                v-slot="{ errors }"
                :name="`cashbackTime.${day.en}.endTime`"
                :rules="{required: true, regex:/^([01][0-9]|2[0-4]):([0-5][0-9])$/}"
            >
              <b-input
                  v-model="form.cashbackTime[day.en].endTime"
                  :disabled="!form.cashbackTime[day.en].isEnable"
                  :state="errors[0] ? false : null"
              />
              <b-form-text>ตัวอย่าง: 24:00</b-form-text>
            </ValidationProvider>
          </b-col>
        </b-row>
      </template>
    </b-form-group>
  </b-card>
</template>

<script>
export default {
  page: {
    title: 'ตั้งค่าเอเย่นต์ | ระบบเงินคืน (แคชแบค)',
  },
  props: {
    agentData: {
      type: Object,
      default: () => ({
        cashbackCommissionRate: 0,
        cashbackQuotaPerDay: 0,
        minWithdrawCashback: 0,
        maxWithdrawCashbackTimePerDay: 0,
        isEnableCashbackTime: false,
        cashbackTime: {
          sunday: { isEnable: false, startTime: '00:00', endTime: '24:00' },
          monday: { isEnable: false, startTime: '00:00', endTime: '24:00' },
          tuesday: { isEnable: false, startTime: '00:00', endTime: '24:00' },
          wednesday: { isEnable: false, startTime: '00:00', endTime: '24:00' },
          thursday: { isEnable: false, startTime: '00:00', endTime: '24:00' },
          friday: { isEnable: false, startTime: '00:00', endTime: '24:00' },
          saturday: { isEnable: false, startTime: '00:00', endTime: '24:00' },
        },
      }),
    },
  },
  data() {
    return {
      form: {},
    }
  },
  computed: {
    daysInWeek() {
      return [
        { en: 'sunday', th: 'วันอาทิตย์' },
        { en: 'monday', th: 'วันจันทร์' },
        { en: 'tuesday', th: 'วันอังคาร' },
        { en: 'wednesday', th: 'วันพุธ' },
        { en: 'thursday', th: 'วันพฤหัสบดี' },
        { en: 'friday', th: 'วันศุกร์' },
        { en: 'saturday', th: 'วันเสาร์' },
      ]
    },
  },
  watch: {
    form: {
      handler(val) {
        this.$emit('update', val)
      },
      deep: true
    }
  },
  created() {
    this.form = {
      cashbackCommissionRate: this.agentData.cashbackCommissionRate,
      cashbackQuotaPerDay: this.agentData.cashbackQuotaPerDay,
      minWithdrawCashback: this.agentData.minWithdrawCashback,
      maxWithdrawCashbackTimePerDay: this.agentData.maxWithdrawCashbackTimePerDay,
      isEnableCashbackTime: this.agentData.isEnableCashbackTime,
      cashbackTime: this.agentData.cashbackTime,
    }
  },
}
</script>

<style></style>
